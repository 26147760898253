import React from 'react';
import { Navbar, Nav, Image, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Menu.css';

import fb from './img/icons8-facebook-48.png'
import ig from './img/icons8-instagram-48.png'

const Menu = () => {
  return (
    <div>
      <Navbar expand="lg" className="bg-body-tertiary shadow-sm p-2 mb-2 rounded" bg="primary" data-bs-theme="dark">
        <div className="container">
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav fill className="justify-content-center">
              <Nav.Link as={Link} to="/" className="link">Acceuil</Nav.Link>
              <Nav.Link as={Link} to="/nos-chiens" className="link">Nos chiens</Nav.Link>
              <Nav.Link as={Link} to="/devenir-famille" className="link">Devenez famille d'élevage</Nav.Link>
              <Nav.Link as={Link} to="/carnet-de-naissances" className="link">Carnet de naissances</Nav.Link>
              <Nav.Link as={Link} to="/contact" className="link">Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <Nav fill className='justify-content-right'>
            <Nav.Link as={Link} to="https://www.facebook.com/people/%C3%89levage-des-Bruy%C3%A8res-de-Servi%C3%A8re/100057480758331/" className="logo">
              <Image src={fb} roundedCircle/>
            </Nav.Link>
            <Nav.Link as={Link} to="https://www.instagram.com/les_bruyeres_de_serviere/" className="logo">
              <Image src={ig} roundedCircle/>
            </Nav.Link>
          </Nav>
        </div>
      </Navbar>
      {/*<div className='container'>
        <h4 className='d-flex justify-content-center'>
          <Alert variant="danger">Des chiots de la portée de <Alert.Link as={Link} to='/nos-chiens' state={{name:'Usher'}}>Usher</Alert.Link> sont disponibles. N'hésitez pas à <Alert.Link as={Link} to='/Contact'>nous contacter</Alert.Link> pour plus d'informations.</Alert>
        </h4>
      </div>*/}
    </div>
  );
}

export default Menu;
