import React from 'react';
import { Helmet } from "react-helmet";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import SwiperGolden from './SwiperGolden';
import CarouselGolden from './CarouselGolden';

import VideoList from './VideoList';

import './Births.css'

const images = require.context('./img/golden/naissance', true);
const imageList = images.keys().map(image => images(image));

const Births = () => {
  return (
    <div className="container">
      <Helmet>
        <title>Dernières naissances de chiots golden retriever</title>
        <meta
          name="description"
          content="Retrouvez l'actualité des dernières naissances de chiots golden retriever de travail dans le Puy-de-dôme de l'élevage au travers de photos et de vidéos."
        />
      </Helmet>
      <h1 className='mb-3'>Carnet de naissances</h1>
      <div>
        <p>Nous n'avons plus de chiots disponibles pour l'instant, n'hésitez pas à nous contacter pour une potentielle réservation sur une prochaine portée.</p>
      </div>
      <div className='d-flex justify-content-center h-100 w-100'>
        <CarouselGolden imageList={imageList}/>
      </div>
      {/*<Tabs>
        <Tab eventKey="Usher" title="Usher">
          <Row>
            <Col>
              <VideoList videos={videos_Usher} nom="Usher" nombre="1"/>
            </Col>
            <Col>
              <div className='d-flex justify-content-center'>
                <Carousel fade>
                    {imageList2.map((image) => (
                        <Carousel.Item>
                            <img loading='lazy' className="d-block w-100" style={{objectFit:"contain"}} src={image} alt="Elevage de golden retriever"/>
                        </Carousel.Item>
                    ))}
                </Carousel>
              </div>
            </Col>
          </Row>
        </Tab>
      </Tabs>*/}
    </div>
  );
};

export default Births;
